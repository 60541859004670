/* eslint-disable */
import ServerUtils from "./helpers";

export default class UserUtils {
  static async getAll() {
    return ServerUtils.GET("api/users/")
  }
  static async update(user) {
    return ServerUtils.POST('api/users/update', {
      user: user
    })
  }
  static async updateImage(user) {
    return ServerUtils.POST('api/users/updateImage', {
      user: user
    })
  }
  static async updateBanner(user) {
    return ServerUtils.POST('api/users/updateBanner', {
      user: user
    })
  }
  static async remove(userId) {
    return ServerUtils.POST('api/users/delete', {
      id: userId,
    })
  }
  static async getProfile(userId) {
    return ServerUtils.GET(`api/users/${userId}/profile`)
  }
  static async sendNewWebsiteEmail(email) {
    return ServerUtils.POST('api/email/send-new-website-email', { email: email })
  }
  static async getAllLogins() {
    return ServerUtils.GET(`api/users/logins/all`);
  }
  static async getStaff() {
    return ServerUtils.GET('api/staff/employees/get-all');
  }
  static async importLegacy(data) {
    return ServerUtils.POST('api/users/import-legacy', { data: data })
  }
  static async importEmployees(data) {
    return ServerUtils.POST('api/users/import-employees', { data: data })
  }

  static async getAllPasswordResets() {
    return ServerUtils.GET('api/users/password-resets/all')
  }
}